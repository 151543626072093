import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import axios from './utils/http'

import '@/assets/iconfont/iconfont.css';
import '@/styles/font.scss';
import '@/styles/theme.scss';
import '@/styles/global.scss';

import { client } from '@ont-dev/ontology-dapi';
import base from './mixin'
import '@/utils/resize';
import { initTheme } from "@/utils/theme";
import i18n from "@/utils/lang";
import VueGtm from '@gtm-support/vue2-gtm';
// import { formatInputNum, formatBack } from "@/utils/format"

// import VConsole from 'vconsole'
// const vConsole = new VConsole()
// Vue.use(vConsole)
// console.info("vconsole test")

import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

Vue.use(VueGtm, {
  id: 'GTM-5ZSRDLH', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '19930518', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [''], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false,
})

Vue.directive('enterNumber', {
  inserted: function (el) {
    el.addEventListener("keypress", (e) => {
      e = e || window.event;
      let charcode = typeof e.charCode == 'number' ? e.charCode : e.keyCode;
      let re = /\d/;
      if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      }
    });
  }
});

Vue.directive('enterDotNumber', {
  inserted: function (el) {
    el.addEventListener("keypress", function (e) {
      e = e || window.event;
      let charcode = typeof e.charCode == 'number' ? e.charCode : e.keyCode;
      let re = /\d/;
      if (charcode == 46) {
        if (el.value.includes('.')) {
          e.preventDefault();
        }
        return;
      } else if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      }
    });
  }
});

Vue.directive('enterDecNumber', {
  inserted: function (el) {
    el.addEventListener("keypress", function (e) {
      e = e || window.event;
      let charcode = typeof e.charCode == 'number' ? e.charCode : e.keyCode;
      let re = /\d/;
      if (charcode == 46) {
        if (el.value.includes('.')) {
          e.preventDefault();
        }
        return;
      } else if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      }
    });
  }
});

function debounce(fn, delay) {
  let timeout = null;
  return function() {
    let context = this, args = arguments;
    clearTimeout(timeout);
    let callNow = !timeout;
    timeout = setTimeout(() => {
      timeout = null;
    }, delay);
    if (callNow) fn.apply(context, args);
  };
}
 
Vue.directive('debounce', {
  bind(el, binding, vnode) {
    let callback = binding.value;
    if (typeof callback !== 'function') {
      throw new Error('directive debounce expects a function as expression');
    }
    let delay = binding.arg ? parseInt(binding.arg, 10) : 500;
    el._debounce_timeout = null;
    el.handler = debounce(function() {
      callback();
    }, delay);
    el.addEventListener('click', el.handler);
  },
  unbind(el) {
    el.removeEventListener('click', el.handler);
  }
});

Vue.prototype.$axios = axios

Vue.prototype.$eventGlobal = new Vue()
Vue.prototype.$cexURL = process.env.VUE_APP_CEX_URL
Vue.prototype.$ooeURL = process.env.VUE_APP_OOE_URL
Vue.prototype.$domain = process.env.VUE_APP_DOMAIN

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})


import { initPrototye } from './prototype.js'
initPrototye(Vue, router)

Vue.config.productionTip = false

client.registerClient({});

Vue.mixin(base)

initTheme();

// import VueLazyload from 'vue-lazyload'
// Vue.use(VueLazyload, {
//   // lazyComponent: true
// })

const instance = new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.$t = instance.$t.bind(instance);

