<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import { getters, state } from './contract'
import { ooeSdk, connectWallet, getAppConnect, getGnosisSafeConnect } from "@/connectWallet";
import { stateGlobal } from '@/globalStore'
import { isApp, isGnosisSafe } from "@/utils/helpers";
import { sleep } from "@/utils";
import { loadConfig } from "@/utils/localCache";
import { getUrlParams } from "@/utils/helpers";

export default {
  computed: {
    ...getters,
  },
  data () {
    return {
      isRouterAlive: true,
    }
  },
  watch: {
    $route: {
      handler: async function (val, oldval) {
        stateGlobal.nowRoute = val
        const params = getUrlParams(val);
        const oldParams = getUrlParams(oldval);
        const isChangChain = params.chain != oldParams.chain || params.chain != state.chainKey;

        state.chainKey = params.chain || loadConfig(`is_connect`) || 'eth';
        const chainObj = ooeSdk.config.chains.getChainByName(state.chainKey)
        if (chainObj) state.chainId = chainObj.chainId
        if (isChangChain && oldval.path != '/') {
          state.account = null;
          state.default_account = null;
          state.default_name = null;
          state.display_account = null;

          if (isApp()) {
            connectWallet()
          } else if (isGnosisSafe()) {
            connectWallet({
              walletKey: "GnosisSafeWallet"
            })
          } else {
            let isConnect = loadConfig('is_connect')
            if (isConnect && chainObj && chainObj.compiler) {
              let walletName = loadConfig(`${chainObj.compiler}_wallet`)
              connectWallet({
                walletKey: walletName
              })
            }
          }
        }

        // Refresh the page, or open it for the first time
        // alert(isApp())
        // console.log('---------')
        // console.log(window.safepal_wallet_app)

        if (oldval.path == '/') {
          if (isApp()) {
            let appObj = await getAppConnect()
            if (appObj.chainKey !== state.chainKey) {
              this.$go('', {
                chain: appObj.chainKey
              })
              return
            } else {
              await sleep(500)
              connectWallet()
            }
          } else if (isGnosisSafe()) {
            let appObj = await getGnosisSafeConnect()
            if (appObj.chainKey !== state.chainKey) {
              this.$go('', {
                chain: appObj.chainKey
              })
              return
            } else {
              await sleep(500)
              connectWallet({
                walletKey: "GnosisSafeWallet"
              })
            }
          } else {
            await sleep(500)
            if (chainObj && chainObj.compiler) {
              let walletName = loadConfig(`${chainObj.compiler}_wallet`)
              if (walletName && !ooeSdk.swapSdk.wallet) {
                connectWallet({
                  walletKey:walletName
                })
                return
              }
            }
          }
        }
      },
      deep: true,
      immediate: false
    },
  },
  mounted () {
    document.body.classList.add('lang-' + this.$i18n.locale.toLowerCase());
    window.reloadPage = (flag) => {
      if (flag) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
          // init({fullPath: window.location.pathname});
          // alert('app init')
          // init();
          
        })
      }
    }
  },
  methods: {

  }
}
</script>

<style>
body {
  font-size: 10pt;
  background: var(--background-root);
  color: var(--color);
  font-family: "Roboto", Helvetica, Arial, "Open Sans", sans-serif;
  font-weight: 400;
}

@media (min-width: 750px) {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
    -webkit-border-radius: 0;
  }

  ::-webkit-scrollbar-thumb:vertical {
    height: 4px;
    background-color: var(--background-scroll);
    -webkit-border-radius: 0;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    width: 4px;
    background-color: var(--background-scroll);
    -webkit-border-radius: 0;
  }
}
</style>
