import Vue from "vue";
import VueRouter from "vue-router";

const Classic = () => import("../pages/ClassicV3/index.vue");
const Career = () => import("../pages/Career/index.vue");
const Migration = () => import("../pages/Migration/index.vue");
const Farms = () => import("../pages/Farms/index.vue");
const Updating = () => import("../pages/Updating");
const Ambassador = () => import("../pages/Ambassador");
const Referral = () => import("../pages/Referral");
const Cross = () => import("../pages/CrossV2/index.vue");
const Tools = () => import("../pages/Tools");
const InfoHub = () => import("../pages/InfoHub");
const Dao = () => import("../pages/Dao");
const SimpleMode = () => import("../pages/SimpleMode");
const NFT = () => import("../pages/NFT/");
const NFTCollection = () => import("../pages/NFT/Collection");
const NFTDetail = () => import("../pages/NFT/Detail");
const NFTSell = () => import("../pages/NFT/Sell");
const NFTProfile = () => import("../pages/NFT/Profile");
const Liquidity = () => import("../pages/Liquidity");
const AddLiquidity = () => import("../pages/Liquidity/Add");
const RemoveLiquidity = () => import("../pages/Liquidity/Remove");
const ImportPool = () => import("../pages/Liquidity/Import");
const Staking = () => import("../pages/Staking");
const Portfolio = () => import("../pages/Portfolio");
const AirDrop = () => import("../pages/AirDrop");
const Markets = () => import("../pages/Markets");

Vue.use(VueRouter);

let routes = [
  {
    path: "/limitorder/:chain/:from/:to",
    name: "LimitOrder",
    component: Classic,
  },
  {
    path: "/limitorder/:chain/:from",
    name: "LimitOrder",
    component: Classic,
  },
  {
    path: "/limitorder/:chain",
    name: "LimitOrder",
    component: Classic,
  },
  {
    path: "/limitorder",
    name: "LimitOrder",
    component: Classic,
  },
  {
    path: "/swap/:chain/:from/:to",
    name: "Swap",
    component: Classic,
  },
  {
    path: "/swap/:chain/:from",
    name: "Swap",
    component: Classic,
  },
  {
    path: "/swap/:chain",
    name: "Swap",
    component: Classic,
  },
  {
    path: "/swap",
    name: "Swap",
    component: Classic,
  },
  {
    path: "/",
    redirect: "/swap",
  },
  {
    path: "/dca/:chain/:from/:to",
    name: "Dca",
    component: Classic,
  },
  {
    path: "/migration",
    name: "Migration",
    component: Migration,
  },
  {
    path: "/updating",
    name: "Updating",
    component: Updating,
  },
  {
    path: "/referral/:walletId",
    name: "Classic",
    component: Classic,
  },
  {
    path: "/careers",
    name: "Career",
    component: Career,
  },
  {
    path: "/referral/:investor/:chainKey/:prevToken/:nextToken",
    name: "Classic",
    component: Classic,
  },
  {
    path: "/referral",
    name: "Referral",
    component: Referral,
  },
  {
    path: "/simple-mode",
    name: "SimpleMode",
    component: SimpleMode,
  },
  {
    path: "/farms",
    name: "Farms",
    component: Farms,
  },
  {
    path: "/vault",
    name: "Vault",
    component: Farms,
  },
  {
    path: "/ambassador-program",
    name: "Ambassador",
    component: Ambassador,
  },
  {
    path: "/tools/:code",
    name: "Tools",
    component: Tools,
  },
  {
    path: "/info-hub",
    name: "InfoHub",
    component: InfoHub,
  },
  {
    path: "/cross",
    name: "Cross",
    component: Cross,
  },
  {
    path: "/portfolio/campaigns",
    name: "Campaigns",
    component: Portfolio,
  },
  {
    path: "/portfolio/dashboard",
    name: "Dashboard",
    component: Portfolio,
  },
  {
    path: "/portfolio/swap",
    name: "Swap",
    component: Portfolio,
  },
  {
    path: "/portfolio/limitorder",
    name: "LimitOrder",
    component: Portfolio,
  },
  {
    path: "/portfolio/cross",
    name: "Cross",
    component: Portfolio,
  },
  {
    path: "/portfolio/staking",
    name: "Staking",
    component: Portfolio,
  },
  {
    path: "/portfolio/nft",
    name: "Nft",
    component: Portfolio,
  },
  {
    path: "/portfolio/airdrop",
    name: "Airdrop",
    component: Portfolio,
  },
  {
    path: "/portfolio/",
    name: "Airdrop",
    component: Portfolio,
  },
  {
    path: "/nft/detail",
    name: "NFTDetail",
    component: NFTDetail,
  },
  {
    path: "/nft/collection/:address",
    name: "NFTCollection",
    component: NFTCollection,
  },
  {
    path: "/nft/profile",
    name: "NFTProfile",
    component: NFTProfile,
  },
  {
    path: "/nft/sell/",
    name: "NFTSell",
    component: NFTSell,
  },
  {
    path: "/nft",
    name: "NFT",
    component: NFT,
  },
  {
    path: "/dao",
    name: "Dao",
    component: Dao,
  },
  {
    path: "/farms",
    name: "Farm",
    component: Farms,
  },
  {
    path: "/liquidity",
    name: "Liquidity",
    component: Liquidity,
  },
  {
    path: "/liquidity/add",
    name: "AddLiquidity",
    component: AddLiquidity,
  },
  {
    path: "/liquidity/remove",
    name: "RemoveLiquidity",
    component: RemoveLiquidity,
  },
  {
    path: "/liquidity/import",
    name: "ImportPool",
    component: ImportPool,
  },
  {
    path: "/staking",
    name: "Staking",
    component: Staking,
  },
  {
    path: "/widget",
    name: "Staking",
    component: Staking,
  },
  {
    path: "/portfolio/airdrop",
    name: "AirDrop",
    component: AirDrop,
  },
  {
    path: "/markets",
    name: "Markets",
    component: Markets,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  return next();
});

export default router;
